<template>
  <div class="page">
    <PageTitle title="积分兑换记录"></PageTitle>
    <div class="content">
      <el-form inline :model="search" class="tb-form">
        <el-form-item label="订单编号：">
          <el-input v-model="search.ordersn"></el-input>
        </el-form-item>
        <!-- <el-form-item label="兑换类型：">
          <el-select v-model="search.branchname">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="手机号：">
          <el-input v-model="search.mobile"></el-input>
        </el-form-item>
        <el-form-item label="下单时间：">
          <el-date-picker
            v-model="search.date"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <div class="tablebox">
        <el-table
          :data="tableData"
          class="tb-table"
        >
          <el-table-column prop="ordersn" label="订单号" min-width="120px" />
          <el-table-column prop="created_at" label="兑换时间" min-width="120px" />
          <el-table-column label="兑换商品">
            <span slot-scope="scope">{{scope.row.items[0].goodsName}}</span>
          </el-table-column>
          <el-table-column prop="integral" label="总积分"/>
          <el-table-column prop="nick_name" label="收货人" min-width="120px"/>
          <el-table-column prop="phone" label="手机号"/>
          <el-table-column prop="statusname" label="状态"/>
        </el-table>
        <el-pagination
          background
          class="tb-pagination"
          layout="prev, pager, next"
          :current-page.sync="page.no + 1"
          :page-size="page.size"
          :total="page.total"
          @current-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
export default {
  name: 'IntegralRecord',
  components: {
    PageTitle
  },
  data() {
    return {
      search: {
        ordersn: '',
        mobile: '',
        date: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let {no, size} = this.page
      let {mobile, date, ordersn} = this.search
      const params = {
        page_size: size,
        page_no: no,
        start_time: date[0] + ' 00:00:00',
        end_time: date[1] + ' 23:59:59',
        mobile,
        ordersn
      }
      const url = 'admin/integral/order/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        item.statusname = '已完成'
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
